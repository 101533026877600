/* 10px simplification: https://material-ui.com/customization/typography/#html-font-size */
/* https://www.sitepoint.com/understanding-and-using-rem-units-in-css/ */

/* index.js: document.getElementById('root') */
/* makes 1rem = 10px for 16px default font browser setting */
/* :root {
  font-size: 62.5%;
} */
html {
  font-size: 62.5%;
}
h1, h2, h3, h4, h5, h6 {
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}
p {
  margin: 0;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}
a {
  display: flex;
  width: auto;
  max-width: fit-content;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}
q {
  padding-left: 0.5rem;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}
pre {
  margin: 0;
  padding: 0.4rem;
  width: auto;
  white-space: pre-wrap;
  font-family: "Roboto Mono", monospace;
  font-size: 1.4rem;
  background-color: #f1f3f4;
  border-radius: 3px;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}
code {
  font-family: "Roboto Mono", monospace;
  font-size: 1.4rem;
  background-color: #f1f3f4;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}
summary {
  font-size: 1.5rem;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}
